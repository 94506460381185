import axios from 'axios';
import { CookieReadConfigEnum } from '../typings';
const timeout = 3000;
const config = {
    timeout,
    withCredentials: true,
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
};
/**
 * TTwid 注册 web_id 并执行回调，将 web_id 同步
 */
export class TtWidCore {
    options;
    host = '';
    firebaseClient;
    /**
     * 创建并返回一个WebId实例。WebId会注册webid，并同步根据情况同步到统一站点和使用方站点
     * @param options
     */
    constructor(options, firebaseClient = null) {
        if (typeof options.union === 'undefined') {
            options.union = true;
        }
        if (typeof options.needFid === 'undefined') {
            options.needFid = true;
        }
        if (options.host) {
            this.host = options.host;
        }
        this.firebaseClient = firebaseClient;
        if (options && options.headers) {
            options.headers['content-type'] = 'application/x-www-form-urlencoded';
            config.headers = options.headers;
            delete options.headers;
        }
        if (options && options.timeout) {
            config.timeout = options.timeout;
            delete options.timeout;
        }
        this.options = options;
    }
    set setFirebaseClient(client) {
        this.firebaseClient = client;
    }
    /**
     *  检查 业务方域名下的web_id是否有效
     */
    async checkWebId(priority) {
        try {
            // eslint-disable-next-line camelcase
            const migrate_priority = priority || CookieReadConfigEnum.COOKIE_READ_DEFAULT;
            let fid = '';
            if (this.options.needFid) {
                fid = this.firebaseClient?.getfid || (await this.firebaseClient?.getInstanceId()) || '';
            }
            const res = (await axios.post(`${this.host}/ttwid/check/`, 
            // eslint-disable-next-line camelcase
            { ...this.options, fid, migrate_priority }, config));
            const { data = null } = res;
            let result = data || res;
            // status_code > 1001 需要注册
            /* istanbul ignore next */
            if (data && data.status_code > 1001) {
                // eslint-disable-next-line camelcase
                const { migrate_info } = data;
                /* istanbul ignore next */
                try {
                    // eslint-disable-next-line max-depth
                    if (this.options.union) {
                        const registerRes = await this.registerUnionWebId({
                            // eslint-disable-next-line camelcase
                            migrate_info,
                            fid,
                        });
                        result = registerRes;
                    }
                    else {
                        const registerRes = await this.registerOpenWebId({
                            // eslint-disable-next-line camelcase
                            migrate_info,
                            fid,
                        });
                        result = registerRes;
                    }
                }
                catch (error) {
                    console.error('ttwid register error', error, error.message);
                    //  callback error return redirect_url
                    /* istanbul ignore next */
                    return data || res;
                }
            }
            return result;
        }
        catch (error) {
            /* istanbul ignore next */
            throw error;
        }
    }
    /**
     *  检查 业务方域名下的 来着 tea SDK 的 web_id 是否有效
     */
    async checkWebIdFromTea() {
        try {
            // 获取 local storage 中的 web_id   __tea_cache_tokens_1652
            const teaDataStr = (localStorage && localStorage.getItem(`__tea_cache_tokens_${this.options.aid}`)) || '';
            const teaData = (teaDataStr && JSON.parse(teaDataStr)) || {};
            // eslint-disable-next-line camelcase
            const { web_id = '' } = teaData;
            /* istanbul ignore next */
            // eslint-disable-next-line camelcase
            if (web_id) {
                // set cookie
                const expiresTime = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString();
                // eslint-disable-next-line camelcase
                document.cookie = `_tea_web_id=${web_id}; expires=${expiresTime}; path=/;`;
            }
            const res = await this.checkWebId(CookieReadConfigEnum.COOKIE_READ_TEA_PRIOR);
            return res;
        }
        catch (error) {
            /* istanbul ignore next */
            throw error;
        }
    }
    /**
     * 注册统一 web_id,并将 web_id 同步到统一站点 unionHost
     */
    async registerUnionWebId(param, callback) {
        try {
            const { unionHost = '', cbUrlProtocol = '' } = this.options;
            // 1、先判断是否使用 指定 host， 2、判断是否使用海外域名
            const res = (await axios.post(`${unionHost}/ttwid/union/register/`, { ...this.options, ...param }, config));
            const { data = null } = res;
            if (data && data.redirect_url) {
                /* istanbul ignore next */
                try {
                    let url = data.redirect_url;
                    // eslint-disable-next-line max-depth
                    if (cbUrlProtocol && url) {
                        url = url.replace(/^https?/, cbUrlProtocol);
                    }
                    const callbackRes = await axios.get(url, config);
                    // eslint-disable-next-line max-depth
                    if (callback) {
                        return callback(null, callbackRes.data || {});
                    }
                    return callbackRes.data || {};
                }
                catch (error) {
                    // eslint-disable-next-line max-depth
                    if (callback) {
                        return callback(error, data || {});
                    }
                    //  callback error return redirect_url
                    return data;
                }
            }
            if (callback) {
                return callback(new Error('ttwid union register error'));
            }
            /* istanbul ignore next */
            throw new Error('ttwid union register error');
        }
        catch (error) {
            if (callback) {
                return callback(error);
            }
            /* istanbul ignore next */
            throw error;
        }
    }
    /**
     * 注册业务方自己的 web_id, 并将 web_id 种上cookie
     */
    async registerOpenWebId(param) {
        try {
            const res = await axios.post(`${this.host}/ttwid/register/`, { ...this.options, ...param }, config);
            return res.data;
        }
        catch (error) {
            /* istanbul ignore next */
            throw error;
        }
    }
    /**
     *  getInstanceId
     *  Firebase 安装 ID (FID)
     *  Creates a Firebase Installation if there isn't one for the app and returns the Installation ID.
     *  Returns string : The identifier for the application instance.
     */
    /* istanbul ignore next */
    async getInstanceId() {
        return this.firebaseClient?.getInstanceId() || '';
        // if (this.installations) {
        //   // eslint-disable-next-line no-async-promise-executor
        //   return new Promise(async (resolve, reject) => {
        //     // setTimeout 2s
        //     setTimeout(() => {
        //       resolve('');
        //     }, 2000);
        //     try {
        //       const fid = await this.installations.getId();
        //       this.fid = fid || '';
        //       resolve(fid || '');
        //     } catch (error) {
        //       this.fid = '';
        //       /* istanbul ignore next */
        //       throw error;
        //     }
        //   });
        // }
        // return '';
    }
    /**
     *  deleteInstallation
     *  Deletes the Firebase Installation and all associated data.
     *  Returns void
     */
    /* istanbul ignore next */
    async deleteInstallation() {
        return this.firebaseClient?.deleteInstallation();
        // try {
        //   // tslint:disable-next-line:no-unused-expression
        //   this.installations && this.installations.delete();
        // } catch (error) {
        //   /* istanbul ignore next */
        //   throw error;
        // }
    }
}
